/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/main.scss');
require('../scss/noscript.scss');
require('@fortawesome/fontawesome-free/css/all.min.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('./jquery.min');
global.$ = global.jQuery = $;
const breakpoints = require('./breakpoints.min');
global.breakpoints = breakpoints;
const browser = require('./browser.min');
global.browser = browser;


require('./util');
require('./main');
require('./vandenbroek');


const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);
